<template>
        <div  class="main-content">
            <loading :active.sync="isLoading"
                     :is-full-page="fullPage"></loading>

            <div class="container-fluid" style="margin-top:0px;" >

                <div class="col-lg-12">
                    <div class="card " style="margin-top: 10px;">
                        <div class="card-header bg-info">
                            <h4 class="m-b-0 text-white" style="font-weight: 700;">New Quotation</h4>
                        </div>
                        <div class="card-body">
                            <form action="#"  v-on:submit.prevent="validateBeforeSubmit" class="form-horizontal" method="post" id="lpo_form" enctype="multipart/form-data">


                                <div class="form-body">

                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="form-group row">
                                                <label class="control-label text-right col-md-3"><strong>Customer:</strong></label>
                                                <div class="col-md-6">

                                                    <multiselect v-model="quotation.customer" @input="openAddNewCustomer"  placeholder="Search Name Or Add New" label="clientName" track-by="id" :options="customers"  ></multiselect>
                                                </div>
                                                <div class="col-md-3">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group row">
                                                <label class="control-label text-right col-md-3"><strong>Ship To / Location: </strong></label>
                                                <div class="col-md-6">
                                                    <input class="form-control" type="text" v-model="quotation.destination" />
                                                </div>
                                                <div class="col-md-3">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group row">
                                                <label class="control-label text-right col-md-3"><strong>Responsible: </strong></label>
                                                <div class="col-md-6">
                                                    <select class="form-control" v-model="quotation.user_id">
                                                        <option :value="user.id" v-for="user in users"> {{ user.name }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3 style="font-weight: 700;">ITEM(S)</h3>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table color-table muted-table">
                                                    <thead>
                                                            <tr>
                                                                <th style="font-weight: 700;">Product</th>
                                                                <th style="font-weight: 700;">Quantity</th>
                                                                <th style="font-weight: 700;">Size In Metres</th>
                                                                <th style="font-weight: 700;">Unit Cost </th>
                                                                <th style="font-weight: 700;">Landing Cost</th>
                                                                <th style="font-weight: 700;">Discount</th>
                                                                <th style="font-weight: 700;">Total Amount</th>
                                                                <th style="font-weight: 700;">Total Length</th>
                                                                <th style="font-weight: 700;">Profit</th>
                                                                <th style="font-weight: 700;">Options</th>
                                                            </tr>
                                                    </thead>
                                                <tbody>

                                                <tr v-for="(quotation_item,index) in quotation.items" >

                                                    <td style="padding: 10px;">
                                                        <!--<multiselect v-model="quotation_item.product"   placeholder="Search Name" track-by="id" :options="filtered_products" :custom-label="customLabel" :show-labels="false"  >

                                                        </multiselect>-->

                                                        <select class="form-control" v-model="quotation_item.product">
                                                            <option :value="catalogueProduct" v-for="catalogueProduct in filtered_products " v-if="catalogueProduct">

                                                                {{ catalogueProduct.productName }}
                                                                 <span v-if="catalogueProduct.gauge">  G {{  catalogueProduct.gauge}}</span>
                                                            </option>
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <input    type="text" class="form-control" v-model="quotation_item.qty" />
                                                    </td>

                                                    <td>
                                                        <input  :disabled="quotation_item.product.productType == 'GENERAL'"   type="text" class="form-control" v-model="quotation_item.size" />

                                                    </td>
                                                    <td style="padding: 10px;">

                                                         <span v-if="quotation_item.product">{{ quotation_item.product.unitCost }}</span>

                                                    </td>

                                                    <td style="padding: 10px;">
                                                        <span v-if="quotation_item.product">
                                                            {{ quotation_item.product.price }}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <input    type="number" class="form-control" v-model="quotation_item.discount" />

                                                    </td>

                                                    <td>
                                                        <div v-if="quotation_item.product && quotation_item.product.productType == 'IRON_SHEET'">
                                                             <span v-if="quotation_item.qty && quotation_item.size" >
                                                              {{(parseFloat(quotation_item.qty) * parseFloat(quotation_item.size) * parseFloat(quotation_item.product.unitCost)) - quotation_item.discount   | number('0,0') }}
                                                            </span>
                                                        </div>
                                                        <div v-else-if="quotation_item.product && quotation_item.product.productType == 'GENERAL'">
                                                            <span v-if="quotation_item.qty" >
                                                              {{( parseFloat(quotation_item.qty)  * parseFloat(quotation_item.product.unitCost)) - quotation_item.discount | number('0,0') }}
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td style="padding: 10px;">
                                                        <div v-if="quotation_item.product && quotation_item.product.productType == 'IRON_SHEET'">
                                                            <span v-if="quotation_item.qty && quotation_item.size"><strong>{{ parseFloat(quotation_item.qty) * parseFloat(quotation_item.size) | number('0,0') }}</strong></span>
                                                        </div>
                                                    </td>
                                                    <td style="padding: 10px;">
                                                        <div v-if="quotation_item.product && quotation_item.qty">
                                                            <div v-if=" quotation_item.product.productType == 'IRON_SHEET'">
                                                                {{ ((quotation_item.product.unitCost * quotation_item.qty * quotation_item.size) - (quotation_item.product.price * quotation_item.qty * quotation_item.size)) | number('0,0') }}
                                                            </div>
                                                            <div v-else>
                                                                {{ ((quotation_item.product.unitCost * quotation_item.qty ) - (quotation_item.product.price * quotation_item.qty )) | number('0,0') }}
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button style="color: #fff;font-weight: bolder;"  v-on:click="deleteRow(index)" type="button" class="btn btn-danger btn-circle btn-sm">Delete </button>
                                                    </td>

                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                    <div class="row" style="margin-top: 50px;">
                                        <div class="col-md-12" >
                                            <button @click="addNewRow" style="cursor: pointer;color: #fff;font-weight: 700;"  type="button" class="btn btn-success btn-sm float-right"> Add new line</button>
                                        </div>
                                    </div>




                                    <div class="col-md-12">
                                        <hr/>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-6">
                                        </div>
                                        <div class="col-md-6">
                                            <div style="background-color: #fbfafa;">
                                                <table class="table">
                                                    <tr>
                                                        <td>
                                                            <b>Sales Revenue:</b>
                                                        </td>
                                                        <td class="text-right">
                                                            <b>{{ grossAmount - discountAmount |  currency("KES ", 2) }}</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Landing Cost:</b>
                                                        </td>
                                                        <td class="text-right">
                                                            <b>{{ landingCostAmount |  currency("KES ", 2) }}</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Transport Cost:</b>
                                                        </td>
                                                        <td class="text-right">
                                                            <b>{{ deliveryFee |  currency("KES ", 2) }}</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Commissions:</b>
                                                        </td>
                                                        <td class="text-right">
                                                            <b>{{ salesCommission |  currency("KES ", 2) }}</b>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <b>Discount Total:</b>
                                                        </td>
                                                        <td class="text-right">
                                                            <b>{{ discountAmount |  currency("KES ", 2) }}</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Net Profit:</b>
                                                        </td>
                                                        <td class="text-right">
                                                            <b>{{ grossAmount - discountAmount - salesCommission - deliveryFee - landingCostAmount |  currency("KES ", 2) }}</b>
                                                        </td>
                                                    </tr>



                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr>
                                            <div class="text-right">
                                                <button v-if="grossAmount > 0" class="btn btn-danger" type="submit"> Submit </button>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { mapGetters, mapActions } from "vuex";


    import {counties} from "@/data/counties";

    export default {
        name: "CreateQuotation",

        data() {
            return {
                users:[],
                counties,
                customer:{
                    id:0,
                    clientName:"",
                    phoneNumber:"",
                    emailAddress:""
                },
                quotation:{
                    customer:"",
                    destination:"",
                    user_id:"",
                    items:[
                        {
                            product:"",
                            qty:"",
                            size:"",
                            discount:0,
                        }
                    ]
                },
                delivery_fees:[],
                isLoading: false,
                products:[],
                commission:{},
                fullPage: true,
                customers:[]
            }
        },

        methods:{
            ...mapActions(["getCustomers","getLocations","getProducts","createQuotation","getDeliveryFees","getSalesCommission","getUsers"]),

            getUsersData(){
                let self = this;
                this.getUsers()
                    .then(function (users) {
                        self.users = users;

                        let loggedInUser = users.filter(function (user) {
                            return user.username = localStorage.getItem("username");
                        });

                        if(loggedInUser.length >  0){
                            self.quotation.user_id = loggedInUser[0].id;
                        }
                    })
                    .catch(function (error) {

                        if( typeof error.response.status != "undefined"){
                            if(error.response.status == 401){
                                location.replace("/app/sessions/signIn");
                            }

                        }
                    })
            },

            getCommissionsSettingsData(){
                let self = this;
                this.getSalesCommission()
                    .then(function (commission) {

                        self.commission = commission;

                    })
                    .catch(function (error) {



                    })
            },

            getDeliveryFeesData(){
                let self = this;
                this.getDeliveryFees()
                    .then(function (delivery_fees) {

                        self.delivery_fees = delivery_fees;

                    })
                    .catch(function (error) {



                    })
            },

            customLabel ({ productName, productFinish, gauge  }) {
                let pFinish = productFinish ? productFinish.finishName : "";
                return `${productName} – ${pFinish} G-${gauge}`
            },

            addNewRow(){

                this.quotation.items.push({
                    product:"",
                    qty:"",
                    size:"",
                    discount:0,
                });


            },

            deleteRow(index){
                this.quotation.items.splice(index, 1);
            },

            getProductsData(){
                let self = this;
                this.getProducts()
                    .then(function (products) {
                        //console.log(products);
                        self.products = products;

                    })
                    .catch(function (error) {



                    })
            },


            getCustomersData(){
                let self = this;
                this.getCustomers()
                    .then(function (customers) {
                        self.customers = customers;

                    })
                    .catch(function (error) {



                    })
            },
            getLocationsData(){
                let self = this;
                this.getLocations()
                    .then(function (locations) {

                    })
                    .catch(function (error) {



                    })
            },
            validateBeforeSubmit(){

                let items_arr = this.quotation.items.map(function (quotation_item) {
                    return {
                        productId: quotation_item.product.id,
                        qty: quotation_item.qty,
                        sizeInMetres: quotation_item.size,
                        discount: quotation_item.discount,
                        price: quotation_item.product.unitCost
                    }
                });



                let quotation = {
                    id:0,
                    destination:this.quotation.destination,
                    customerId: this.quotation.customer.id,
                    userId: this.quotation.user_id,
                    items: items_arr
                };



                this.isLoading = true;

                let self = this;

                this.createQuotation(quotation).then(() => {
                    self.isLoading = false;

                    self.$bvToast.toast("Quotation saved.", {
                        title: 'Success',
                        variant: 'success',
                        autoHideDelay: 5000,
                        appendToast: false
                    });

                    location.replace("/app/pages/finance")
                })
                    .catch(error => {

                        self.isLoading = false;

                        self.$bvToast.toast(error.message, {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000,
                            appendToast: false
                        });

                    })

            },
            openAddNewCustomer(){

                if(this.quotation.customer.clientName == "Add New Customer"){
                    this.quotation.customer = {};
                    console.log("Open new modal");

                    this.$bvModal.show('add-customer');
                }

            },
            openAddNewLocation(){

                if(this.quotation.location.locationName == "Add New Location"){
                    this.quotation.location = {};
                    console.log("Open new modal location");
                    this.$refs.addlocation.openModal();
                }

            }
        },
        mounted (){
            this.getUsersData();
            this.getProductsData();
            this.getCustomersData();
            this.getLocationsData();
            this.getDeliveryFeesData();
            this.getCommissionsSettingsData();
        },
        computed: {
            salesCommission(){
                if(this.commission){
                    switch (this.commission.commissionType) {
                        case "FIXED":
                            return this.commission.amount;
                            break;
                        case "PERCENTAGE":
                            if(this.grossAmount > 0){
                               return parseFloat((this.commission.amount / 100) * this.grossAmount);
                            }
                            else{
                                return 0;
                            }
                            break;

                    }
                }
                else {
                    return 0;
                }
            },
            deliveryFee(){
                if(this.quotation.location){


                    let self = this;

                    let deliveryFeeCost = this.delivery_fees.filter(function (delivery_fee) {

                        return delivery_fee.county == self.quotation.location.county;
                    });
                    if(deliveryFeeCost.length > 0){
                        return deliveryFeeCost[0].deliveryFee;
                    }
                    else {
                        return -1
                    }

                }
                else{
                    return 0
                }
            },
            /*filtered_products(){
                let self = this;

                if(parseInt(localStorage.getItem("locationId")) > 0){
                    let county = this.counties.filter(function (county) {
                        return county.code == parseInt(localStorage.getItem("locationId"))
                    });


                    if(county.length > 0){
                        let products = this.products.map(function (product) {


                            if(product.productName){
                                let productPrice = product.productPriceLists.filter(function (productPriceList) {
                                    return productPriceList.county == county[0].name;
                                });

                                if(productPrice.length > 0){
                                    return {
                                        gauge: product.gauge,
                                        id: product.id,
                                        price: product.price,
                                        productFinish: product.productFinish,
                                        productName: product.productName,
                                        unitCost: productPrice[0].price,
                                        productType: product.productType,
                                        qbInventoryItemId: product.qbInventoryItemId
                                    }
                                }
                            }




                        });


                        return products;

                        //return [];
                    }
                    else{
                        return []
                    }

                }
                else{
                    return [];
                }


            },*/
            filtered_products(){
                let products = this.products.map(function (product) {

                    //console.log(product);


                    if(product.productName){
                        if(product.productPriceLists.length > 0){
                            return {
                                gauge: product.gauge,
                                id: product.id,
                                price: product.price,
                                productFinish: product.productFinish,
                                productName: product.productName,
                                unitCost: product.productPriceLists[0].price,
                                productType: product.productType,
                                qbInventoryItemId: product.qbInventoryItemId
                            }
                        }


                    }


                });

                //console.log(products);

                return products;
            },
            grossAmount(){

                let fixed_amount = 0;

                this.quotation.items.forEach(function(quotation_item){
                    if(quotation_item.product && quotation_item.product.productType == 'IRON_SHEET'){
                        fixed_amount +=  (parseFloat(quotation_item.qty) * parseFloat(quotation_item.size) * parseFloat(quotation_item.product.unitCost))
                    }
                    else{
                        fixed_amount += parseFloat(quotation_item.qty)  * parseFloat(quotation_item.product.unitCost);
                    }

                });

                return fixed_amount;
            },
            landingCostAmount(){

                let fixed_amount = 0;

                this.quotation.items.forEach(function(quotation_item){
                    if(quotation_item.product && quotation_item.product.productType == 'IRON_SHEET'){
                        fixed_amount +=  (parseFloat(quotation_item.qty) * parseFloat(quotation_item.size) * parseFloat(quotation_item.product.price))
                    }
                    else{
                        fixed_amount += parseFloat(quotation_item.qty)  * parseFloat(quotation_item.product.price);
                    }

                });

                return fixed_amount;
            },
            discountAmount(){

                let fixed_amount = 0;

                this.quotation.items.forEach(function(quotation_item){
                    fixed_amount += parseFloat(quotation_item.discount);

                });

                return fixed_amount;
            },
            locations(){
                return this.$store.state.settingsData.locations;
            }
        },
        components: {
            Loading,
            Multiselect
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
